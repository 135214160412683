import React from "react";
import { Box, VStack } from "@chakra-ui/react";
import PlantBasedWishes from "./PlantBasedWishes";
import GenerateButton from "./GenerateButton";
import Logo from "./Logo";
import useRecipeConfigStore from "hooks/useRecipeConfigStore";
import { useNavigate } from "react-router-dom";
import CriteriaButtonGroup from "./CriteriaButtonGroup";

const RecipeGenerator = () => {
  const {
    criterias,
    additionalRemarks,
    setAdditionalRemarks,
    isIngredientSelectorEnabled,
    selectIngredients,
    generateRecipe,
    reset,
  } = useRecipeConfigStore();
  const navigate = useNavigate();

  return (
    <>
      <Logo />
      <VStack spacing={4} alignItems="center" textAlign="center" w="100%">
        <Box h="1px" w="75%" bgColor="gray.200" />
        <PlantBasedWishes
          remarks={additionalRemarks}
          onChange={(e) => setAdditionalRemarks(e.target.value)}
        />
        <Box h="1px" w="75%" bgColor="gray.200" />
        <CriteriaButtonGroup />
        <Box h="1px" w="75%" bgColor="gray.200" />
        <GenerateButton
          onClick={() => {
            reset();
            if (isIngredientSelectorEnabled) {
              selectIngredients(criterias, additionalRemarks);
              navigate("/select-ingredients");
              return;
            }
            generateRecipe();
            navigate("/recipe");
          }}
        />
      </VStack>
    </>
  );
};

export default RecipeGenerator;
