import { create } from "zustand";
import { persist } from "zustand/middleware";

const criteriaList = [
  "convenient",
  "cheap",
  "creative",
  "delicious",
  "healthy",
];

const initialCriteria = Object.fromEntries(
  criteriaList.map((criteria) => [criteria, false])
);

const initialState = {
  criteriaList: criteriaList,
  criterias: initialCriteria,
  additionalRemarks: "",
  isIngredientSelectorEnabled: false,
  recipe: {},
  recipeError: null,
  generatingRecipe: false,
  ingredientTree: {
    error: null,
    warning: "",
    ingredients: [],
  },
  eventSource: null,
};

const API_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8080"
    : "https://happyrecipebe.herokuapp.com";

const useRecipeConfigStore = create(
  persist(
    (set, get) => ({
      ...initialState,
      toggleCriteria: (criteria) => {
        set({
          criterias: {
            ...get().criterias,
            [criteria]: !get().criterias[criteria],
          },
        });
      },
      setAdditionalRemarks: (additionalRemarks) => set({ additionalRemarks }),
      setIsIngredientEnabled: (isIngredientEnabled) =>
        set({ isIngredientEnabled }),
      selectIngredients: async (criterias, additionalRemarks) => {
        // ... (unchanged)
      },
      reset: () => {
        const { eventSource } = get();
        if (eventSource) {
          eventSource.close();
        }
        set({ recipe: {}, recipeError: null });
      },
      cancelRecipeGeneration: () => {
        const { eventSource } = get();
        if (eventSource) {
          eventSource.close();
        }
        set({ generatingRecipe: false, eventSource: null });
      },
      generateRecipe: async () => {
        const { generatingRecipe, eventSource } = get();
        if (generatingRecipe) {
          if (eventSource) {
            eventSource.close();
          }
          set({ generatingRecipe: false, eventSource: null });
        }

        set({ recipeError: null, generatingRecipe: true, recipe: {} });

        const criteriaQueryParam = `criterias=${JSON.stringify(
          get().criterias
        )}`;
        const additionalRemarksQueryParam = `additionalRemarks=${encodeURIComponent(
          get().additionalRemarks
        )}`;
        const source = new EventSource(
          `${API_URL}/generate-recipe?${[
            criteriaQueryParam,
            additionalRemarksQueryParam,
          ]
            .filter((q) => q)
            .join("&")}`
        );

        set({ eventSource: source });

        source.onmessage = (event) => {
          if (!event.data) {
            return;
          }

          const newRecipeData = JSON.parse(event.data);
          if (newRecipeData.endOfStream) {
            source.close();
            set({ generatingRecipe: false, eventSource: null });
            return;
          }

          let updatedRecipe = { ...get().recipe };
          Object.entries(newRecipeData)
            .filter(([_, value]) => value)
            .forEach(([key, value]) => {
              if (!updatedRecipe[key]) {
                updatedRecipe[key] = "";
              }
              updatedRecipe = {
                ...updatedRecipe,
                [key]: updatedRecipe[key] + newRecipeData[key],
              };
            });

          set({ recipe: updatedRecipe });
        };

        source.onerror = (error) => {
          source.close();
          set({
            generatingRecipe: false,
            recipeError: error,
            eventSource: null,
          });
        };
      },
    }),
    {
      name: "happy-recipe-config-store",
      version: 2,
    }
  )
);

export default useRecipeConfigStore;
