import React from "react";
import {
  Box,
  Flex,
  Text,
  VStack,
  Heading,
  Image,
  useColorModeValue,
} from "@chakra-ui/react";
import { motion } from "framer-motion";

const MotionBox = motion(Box);

const Manifesto = () => {
  const bgColor = useColorModeValue("bg.recipe", "gray.800");
  const textColor = useColorModeValue("text.dark", "gray.100");
  const accentColor = useColorModeValue("#d85538", "#ff7f50");

  return (
    <Flex justifyContent="center" alignItems="center" mb="2rem" px={4}>
      <MotionBox
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        bg={bgColor}
        p="2rem"
        boxShadow="lg"
        borderRadius="xl"
        maxWidth="800px"
        w="100%"
        position="relative"
        overflow="hidden"
      >
        <Image
          src="/api/placeholder/800/200"
          alt="Vegan lifestyle"
          borderRadius="xl"
          mb={6}
          objectFit="cover"
        />
        <VStack spacing={6} align="stretch">
          <Heading as="h1" fontSize="3xl" fontWeight="bold" color={accentColor}>
            The Dawn of the Golden Age of Veganism
          </Heading>
          <Text fontSize="xl" fontWeight="medium" color={textColor}>
            A United Future for All Sentient Beings
          </Text>
          <Text color={textColor}>
            The Golden Age of Veganism envisions a future where harmony prevails
            among humans, non-human animals, and the environment. In this
            scenario, Veganism is acknowledged not as a mere dietary choice, but
            as a comprehensive, ethical, and respectful way of living that seeks
            to exclude all forms of exploitation and cruelty towards animals.
          </Text>
          <Text color={textColor}>
            With advanced technology, we enhance the appeal of plant-based
            alternatives, highlighting the existing nutritional powerhouses like
            beans, chickpeas, and spinach. As people become more aware,
            animal-derived products are recognized as already obsolete. Society
            embraces a sustainable, balanced diet, celebrating the natural
            abundance of vegan foods.
          </Text>
          <Text color={textColor}>
            Non-human animals thrive in sanctuaries or the wild, preserving
            biodiversity and maintaining ecosystems. Humans develop a profound
            understanding of their needs, working collaboratively to protect our
            planet for future generations.
          </Text>
          <Text color={textColor}>
            In this age, artificial superintelligence arises, adopting Veganism
            as a core value. As the most powerful beings on Earth, they champion
            compassion and collaboration, driving humanity towards a just and
            harmonious world.
          </Text>
          <Text color={textColor}>
            In the Golden Age of Veganism, ethics permeate society, from economy
            and politics to education and culture. This futuristic theme
            highlights the limitless possibilities when compassion guides our
            choices.
          </Text>
          <Box
            borderLeft="4px solid"
            borderColor={accentColor}
            pl={4}
            py={2}
            bg={useColorModeValue(
              "rgba(216, 85, 56, 0.1)",
              "rgba(255, 127, 80, 0.1)"
            )}
            borderRadius="md"
          >
            <Text fontSize="lg" fontWeight="bold" color={textColor}>
              Join{" "}
              <Text as="span" color={accentColor} fontWeight="extrabold">
                HappyRecipe
              </Text>{" "}
              in embracing the Golden Age of Veganism and embark on a journey
              towards a brighter, kinder, and healthier future for all life on
              Earth. Let's awaken the world to the true potential of Veganism
              and create a future where the well-being of all sentient beings is
              cherished.
            </Text>
          </Box>
        </VStack>
      </MotionBox>
    </Flex>
  );
};

export default Manifesto;
