import React from "react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Box,
  Heading,
  Text,
  Spinner,
  VStack,
  Image,
  Skeleton,
} from "@chakra-ui/react";
import { WarningIcon } from "@chakra-ui/icons";
import Logo from "./Logo";
import useRecipeConfigStore from "hooks/useRecipeConfigStore";

const RecipeDisplay = ({ onGenerateAnotherRecipe }) => {
  const [imageLoaded, setImageLoaded] = React.useState(false);

  const {
    recipe,
    generatingRecipe,
    recipeError: errorGeneratingRecipe,
  } = useRecipeConfigStore();
  if (errorGeneratingRecipe) {
    return (
      <Box
        w="100%"
        h="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Alert
          status="error"
          borderRadius="lg"
          bgColor="red.300"
          boxShadow="lg"
          p={4}
          lineHeight="1.6"
          letterSpacing="0.02em"
        >
          <AlertIcon
            as={WarningIcon}
            boxSize={{ base: "20px", md: "24px" }}
            color="black"
          />
          <AlertTitle
            fontSize={{ base: "md", md: "lg" }}
            fontWeight="500"
            color="red.800"
          >
            <Text>
              We are sorry, but we could not generate a recipe for you. Please
              try again later.
            </Text>
          </AlertTitle>
        </Alert>
        <Button
          size="lg"
          bgColor="text.green"
          boxShadow="md"
          onClick={onGenerateAnotherRecipe}
          _hover={{
            boxShadow: "lg",
          }}
          _active={{
            boxShadow: "xl",
          }}
          my={4}
        >
          <Text color="bg.recipe">Generate Another Recipe</Text>
        </Button>
      </Box>
    );
  }

  return (
    <>
      <Logo />
      <VStack spacing={6} alignItems="center" textAlign="center" w="100%">
        {recipe.warning && (
          <Box w="100%">
            <Alert
              status="warning"
              borderRadius="lg"
              bgColor="yellow.300"
              boxShadow="lg"
              p={4}
              lineHeight="1.6"
              letterSpacing="0.02em"
            >
              <AlertIcon
                as={WarningIcon}
                boxSize={{ base: "20px", md: "24px" }}
                color="black"
              />
              <AlertTitle
                fontSize={{ base: "md", md: "lg" }}
                fontWeight="500"
                color="yellow.800"
              >
                {recipe.warning}
              </AlertTitle>
            </Alert>
          </Box>
        )}
        <Box
          padding={6}
          fontSize={{ base: "md", md: "lg" }}
          fontWeight="500"
          color="text.dark"
          w="100%"
          lineHeight="1.6"
          letterSpacing="0.02em"
          bgColor="bg.recipe"
          boxShadow="lg"
          borderRadius="xl"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {recipe.title && (
            <Heading as="h2" size="xl" color="text.dark" mb={2}>
              {recipe.title}
            </Heading>
          )}
          {recipe.summary && (
            <Text
              as="p"
              mb={4}
              fontSize={{ base: "xl", md: "2xl" }}
              fontWeight="bold"
              color="text.dark"
            >
              {recipe.summary}
            </Text>
          )}
          <>
            <Box h="1px" w="100%" bgColor="gray.200" my={2} />
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              w="100%"
              position="relative"
            >
              <Skeleton
                isLoaded={imageLoaded}
                startColor="gray.200"
                endColor="bg.recipe"
                width="256px"
                height="256px"
                borderRadius="24px"
              >
                <Image
                  id="recipe-image"
                  src={`data:image/png;base64,${recipe.imageURL}`}
                  alt="Recipe Image"
                  borderRadius="24px"
                  width="256px"
                  height="256px"
                  objectFit="cover"
                  onLoad={() => setImageLoaded(true)}
                />
              </Skeleton>
            </Box>
          </>
          {recipe.ingredients && (
            <>
              <Box h="1px" w="100%" bgColor="gray.200" my={2} />
              <Heading
                as="h3"
                size="md"
                color="text.dark"
                textAlign="center"
                mt={2}
                mb={4}
              >
                Ingredients
              </Heading>
              <VStack
                spacing={2}
                alignItems="flex-start"
                textAlign="left"
                w="100%"
              >
                <Box
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="space-between"
                  mb={4}
                >
                  {(() => {
                    const ingredients = recipe.ingredients
                      .split("\n")
                      .filter(
                        (line) => line !== "" && line !== "\n" && line !== " "
                      );
                    const numIngredients = ingredients.length;
                    const columns = Math.min(3, numIngredients);

                    return ingredients.map((line, index) => (
                      <Text
                        as="p"
                        key={index}
                        mb={2}
                        textAlign="left"
                        color="text.dark"
                        flexBasis={{
                          base: "100%",
                          md: `calc(${100 / columns}% - 8px * ${
                            columns - 1
                          } / ${columns})`,
                        }}
                      >
                        {line}
                      </Text>
                    ));
                  })()}
                </Box>
              </VStack>
            </>
          )}
          {recipe.recipe && (
            <>
              <Box h="1px" w="100%" bgColor="gray.200" my={4} />
              <Heading
                as="h3"
                size="md"
                textAlign="left"
                mb={4}
                color="text.dark"
              >
                Recipe Steps
              </Heading>
              {recipe.recipe.split("\n").map((line, index) => (
                <Text
                  as="p"
                  key={index}
                  mb={2}
                  w="100%"
                  textAlign="left"
                  color="text.dark"
                >
                  {line}
                </Text>
              ))}
            </>
          )}
        </Box>
        {generatingRecipe ? (
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="coolGreen.500"
            size="lg"
          />
        ) : (
          <Button
            size="lg"
            bgColor="text.green"
            boxShadow="md"
            onClick={onGenerateAnotherRecipe}
            _hover={{
              boxShadow: "lg",
            }}
            _active={{
              boxShadow: "xl",
            }}
          >
            <Text color="bg.recipe">Generate Another Recipe</Text>
          </Button>
        )}
      </VStack>
    </>
  );
};

export default RecipeDisplay;
